import store from '@/store'

const { congressAll } = store.state.congress
const { user } = store.state.auth
const congressChil = []
const menu = []
//
menu.push({
  title: 'Inicio',
  route: 'home_plataforma',
  icon: 'HomeIcon',
})
menu.push({
  title: 'Congressos',
  icon: 'MonitorIcon',
  route: 'congress_home',
})
menu.push({
  title: 'Cursos',
  icon: 'FilmIcon',
  route: 'cursos',
})
menu.push({
  title: 'Lives',
  icon: 'PlayIcon',
  route: 'lives',
})
menu.push({
  title: 'Podcasts',
  icon: 'HeadphonesIcon',
  route: 'podcasts',
})
menu.push({
  title: 'Revistas',
  route: 'list_magazine',
  icon: 'BookOpenIcon',
})
menu.push({
  title: 'Certificados',
  route: 'Certificate',
  icon: 'DownloadIcon',
})
export default menu
